@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	.font-outline-2 {
		-webkit-text-stroke: .5px black;
	}

	.font-outline-4 {
		-webkit-text-stroke: 4px black;
	}
}


/* @font-face {
	font-family: 'Satoshi';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		url('./assets/satoshi/Satoshi-Regular.woff2') format('woff2'),
		url('./assets/satoshi/Satoshi-Regular.woff') format('woff');
} */


@font-face {
	font-family: 'ComicNeue';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		url('./assets/comic/ComicNeue-Regular.ttf');
}


html {
	font-family: 'ComicNeue';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'ComicNeue';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}